import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CharityIssue } from '../../models/CharityIssue';


@Injectable({ providedIn: 'root' })
export class CharityIssueService {
    charityIssueChanged$: Subject<CharityIssue> = new BehaviorSubject(null);

    constructor() {
    }

    getSelectedCharityIssue(): Observable<CharityIssue> {
        return this.charityIssueChanged$.asObservable();
    }

    setSelectedCharityIssue(charityIssueCategory: CharityIssue) {
        this.charityIssueChanged$.next(charityIssueCategory);
    }
}
