import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CharityIssueCategory } from '../../models/CharityIssueCategory';


@Injectable({ providedIn: 'root' })
export class CharityCategoryService {
    categoryChanged$: Subject<CharityIssueCategory> = new BehaviorSubject(null);

    constructor() {
    }

    getSelectedCharityCategory(): Observable<CharityIssueCategory> {
        return this.categoryChanged$.asObservable();
    }

    setSelectedCharityIssueCategory(charityIssueCategory: CharityIssueCategory) {
        this.categoryChanged$.next(charityIssueCategory);
    }
}
