import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class BalanceService {
    private fetchBalance$ = new Subject();

    constructor(private apiSvc: ApiService) {

    }

    getBalance(): Observable<number> {
        return this.fetchBalance$.pipe(
            startWith(0),
            switchMap(() => {
                return this.apiSvc.getBalance();
            }),
            shareReplay(1)
        );
    }

    forceFetch() {
        this.fetchBalance$.next();
    }
}
